const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://kayna.dvgeo.app' : 'http://192.168.100.106:3047',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://kayna.dvgeo.app' : 'http://192.168.100.106:3047',
    api: 'api/',
    apiSocket: 'kayna::1.2',
    nameDir: 'kayna',
    package: 'app.dvgeo.kayna.web',
    version: '1.0.2',
    googleAPIKey: 'AIzaSyDuUCbu2vUXgqum0_LSlRIvhHe8jkusx-g',
    appName: 'ALO G 14',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#093782',
    colorDark: '#FF6600',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.kayna.passenger',
    playStoreDriverId: 'app.dvgeo.kayna.driver',
    appStorePassengerId: '6451396643',
    appStoreDriverId: '6451396564',
    email: "Percy98730@gmail.com",
};
export default config;
